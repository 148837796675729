/* eslint-disable max-lines */
export default {
  msg_app_action_reload: 'Perkrauti',
  msg_page_bulk_adverts_upload: 'Skelbimų failo įkėlimas',
  msg_error_invalid_advert_category: 'Neteisingai pasirinkta skelbimo kategorija',
  msg_my_ads_bulk_upload_button: 'Įkelkite skelbimų failą',
  msg_bulk_upload_page_hero_title: 'Vienu metu įkelkite keletą skelbimų!',
  msg_bulk_upload_page_hero_subtext: 'Čia galite įkelti skelbimų sąrašą, kuris bus viešas arba matomas tik jums.',
  msg_bulk_upload_form_title: 'Skelbimų įkėlimo forma',
  msg_bulk_upload_form_ad_privacy_field: 'Nurodykite įkeliamų skelbimų pasiekiamumą',
  msg_bulk_upload_form_ad_privacy_public_type: 'Vieši skelbimai',
  msg_bulk_upload_form_ad_privacy_private_type: 'Tik jums matomi skelbimai',
  msg_bulk_upload_form_upload_file_field: 'Skelbimų sąrašas (.csv formatu)',
  msg_btn_csv_upload: 'Įkelti',
  msg_button_submit_bulk_upload: 'Pateikti',
  msg_error_csv_import_invalid_file_format: 'Netinkamas formatas, įkelkite .csv failą.',
  msg_bulk_upload_completed_title: 'Skelbimai sėkmingai įkelti!',
  msg_bulk_upload_completed_subtitle: 'Juos galite matyti skiltyje "Mano skelbimai"',
  msg_error_csv_import_invalid_value:
    'Klaida eilutėje {{lineNumber}}: stulpelio "{{columnTitle}}" reikšmė "{{ value }}" netinkama',
  msg_error_csv_import_specify_error_location: 'Klaida eilutėje {{lineNumber}}: stulpelio "{{columnTitle}}"',
  msg_company_phone_label: 'tel.',
  msg_advert_create_advert_location_field_explanation:
    'Nurodykite tikslų adresą, kad skelbimas būtų matomas žemėlapyje',
  msg_advert_create_advert_valid_until: 'Galioja iki',
  msg_orders_map_no_results_found: 'Rezultatų nerasta',
  msg_btn_submit_filters: 'Ieškoti',
  msg_advert_create_status_selection_field_field_description: 'Nustatykite skelbimo prieinamumą',
  msg_advert_create_status_selection_placeholder: 'Pasirinkite skelbimo prieinamumą',
  msg_ad_status_active_selection: 'Viešas skelbimas',
  msg_ad_status_private_selection: 'Privatus skelbimas',
  msg_order_total_order_price: 'Užsakymo kaina',
  msg_order_item_order_date: 'Užsakymo data',
  msg_bank_info_updated_title_create: 'Banko pavedimo informacija išsaugota!',
  msg_bank_info_updated_subtitle_create: 'Informaciją galėsite keisti.',
  msg_userpage_transfer_info_button_create: 'Pridėti banko pavedimo informaciją',
  msg_error_invalid_iban: 'Neteisingas sąskaitos nr.',
  btn_label_clear_filters: 'Išvalyti filtrus',
  msg_btn_hide_location_bar: 'Suskleisti filtrus',
  msg_btn_show_location_bar: 'Išskleisti filtrus',
  msg_label_authentication_tour_auth_step1:
    'Užpildykite registracijos laukus, nurodydami el. pašto adresą, vartotojo vardą bei slaptažodį.',
  msg_label_authentication_tour_auth_step2: 'Pažymėkite, kad sutinkate su mūsų Privatumo politika',
  msg_label_authentication_tour_auth_step3: 'Spauskite "Registruotis" ir tapkite mūsų bendruomenės nariu!',
  msg_label_company_tour_company_step1: 'Nurodykite savo statusą ir užpildykite informaciją.',
  msg_label_company_tour_company_step2: 'Išsaugokite pateiktą informaciją.',
  msg_label_company_tour_company_step3:
    'Viskas! Galite keliauti į prekyvietę ir naudotis Sejico paslaugomis ir privalumais!',
  msg_company_advert_is_set_to_private: 'Skelbimas matomas tik grupėje!',
  msg_company_advert_private_status_title: 'Paspauskite, norėdami skelbimą rodyti grupės nariams',
  msg_company_advert_private_status_subtitle: 'Skelbimas bus rodomas tik jūsų grupės nariams',
  msg_status_created_email: 'Jūs gavote naują užsakymą!',
  msg_status_canceled_email: 'Užsakymas buvo atšauktas!',
  msg_status_unfinished_email: 'Užsakymas yra nebaigtas!',
  msg_status_finished_email: 'Užsakymas įvykdytas!',
  msg_status_expired_email: 'Užsakymas pasibaigė. Prašome padaryti naują užsakymą.',
  msg_status_ended_email: 'Užsakymas baigėsi!',
  msg_status_declined_email: 'Užsakymas buvo atmestas!',
  msg_status_active_email: 'Užsakymas yra aktyvus!',
  msg_status_accepted_email: 'Užsakymas buvo priimtas!',
  msg_my_adverts_display_views_count: '{{viewCount}} peržiūrų',
  msg_thread_created_at_date: 'Sukurta {{date}}',
  msg_order_id_in_confirm_services_received: 'Nr. {{orderId}}',
  msg_label_footer_text:
    'Projektas finansuojamas pagal 2014 – 2020 m Europos Sąjungos fondų investicijų veiksmų programos 1 prioriteto „Mokslinių tyrimų, eksperimentinės plėtros ir inovacijų skatinimas“ įgyvendinimo priemonę Nr. J05-LVPA-K „Intelektas. Bendri mokslo – verslo projektai“.',
  msg_auction_bidders: 'dalyvauja {{value}}',
  msg_auction_active_status_with_expiration: 'Liko {{value}}',
  msg_auction_active_status_no_expiration: '-',
  msg_label_ago: '{{value}}',
  msg_verification_email_title: 'Elektroninis paštas patvirtintas!',
  msg_verification_email_subtitle: 'Galite pradėti naudotis Sejico!',
  msg_userpage_update_company_tab: 'Ūkio informacija',
  msg_userpage_update_billing_info_tab: 'Sąskaitos informacija',
  msg_userpage_invoice_info_tab: 'Mokėjimo išrašas',
  msg_userpage_company_reviews_tab: 'Mano įvertinimai',
  msg_sort_adverts_option_advert_newest: 'Naujausius viršuje',
  msg_userpage_change_payments_tab: 'Mokėjimo kortelės',
  msg_userpage_change_password_tab: 'Vartotojo informacija',
  msg_switcher_topic: 'Pasirinkite kryptį',
  msg_switcher_kind_agro: 'Sejico Agro',
  msg_switcher_kind_agro_short: 'Agro',
  msg_switcher_kind_constructions_short: 'Construction',
  msg_switcher_kind_constructions: 'Sejico Construction',
  msg_switcher_kind_agro_paragraph: 'Žemės ūkio nuoma ir skelbimai',
  msg_switcher_kind_constructions_paragraph: 'Statybinės technikos nuoma ir skelbimai',
  msg_switcher_button_go: 'Eiti',
  msg_tooltip_company_phone_title: 'Įveskite telefono numerį',
  msg_tooltip_company_phone_subtitle: 'Telefono numerio formatas +370..',
  msg_tooltip_company_name_title: 'Ūkio pavadinimas',
  msg_sort_adverts_option_advert_lowest_price: 'Žemiausia kaina viršuje',
  msg_tooltip_company_name_subtitle: 'Įveskite ūkio pavadinimą, kurį matys kiti vartotojai.',
  msg_tooltip_company_area_title: 'Pasirinkite ūkio veiklos kodą.',
  msg_tooltip_company_area_subtitle: 'Pasirinkite veiklą, labiausiai atitinkančią Jūsų ūkį.',
  msg_ton_amount: 'Nurodykite kainą (eur/t)',
  msg_title_website_title: 'Sejico',
  msg_thread_your_thread_reply: 'Rašyti komentarą',
  msg_thread_reply_success_title: 'Dėkojame už komentarą!',
  msg_thread_reply_logged_out_form: 'Komentuoti gali tik prisijungę vartotojai',
  msg_thread_reply_author_info: 'Originali žinutė',
  msg_thread_posted_by_author: 'Autorius {{author}}',
  msg_thread_posted_by: 'Autorius',
  msg_thread_logged_out_create_form: 'Komentuoti gali tik prisijungę vartotojai',
  msg_thread_created_at: 'Sukurta',
  msg_thread_add_thread_reply: 'Komentuoti',
  msg_sort_adverts_option_advert_highest_rating: 'Geriausiai įvertinti viršuje',
  msg_sort_adverts_option_advert_highest_price: 'Didžiausia kaina viršuje',
  msg_sort_adverts_desc_title: 'Pagal pavadinimą (Z-A)',
  msg_sort_adverts_by: 'Rodyti',
  msg_sort_adverts_asc_title: 'Pagal pavadinimą (A-Z)',
  msg_service_is_not_received_subheader: 'Paslaugos nebuvo suteiktos arba prekės nebuvo pristatytos.',
  msg_service_is_not_received_modal_button: 'Patviritnu neatliktą užsakymą',
  msg_service_is_not_received_header: 'Užsakymas nebuvo atliktas!',
  msg_sent_orders_tab: 'Pateikti užsakymai',
  msg_search_results: 'Paieškos rezultatai',
  msg_search_bar_small_placeholder: 'Ieškoti',
  msg_search_bar_placeholder: 'Įveskite paieškos žodį',
  msg_reset_password_title: 'Įveskite naują slaptažodį',
  msg_reset_password_success_title: 'Slaptažodis sėkmingai pakeistas!',
  msg_reset_password_success_subtitle: 'Prisijunkite naudodami naują slaptažodį.',
  msg_calendar_next: 'Pirmyn',
  msg_calendar_date: 'Data',
  msg_reset_password_subtitle: 'Slaptažodis turi būti bent 5 simbolių. Bent vienas simbolis turi būti skaičius.',
  msg_register_terms_of_usage:
    'Perskaičiau ir sutinku su svetainės <a href="/page/salygos" target="_blank"> naudojimosi taisyklėmis</a> ir <a href="/page/privatumo-politika" target="_blank">privatumo politika</a>.',
  msg_register_marketing_material: 'Sutinku gauti rinkodaros tikslais siunčiamą informaciją.',
  msg_register_form_register: 'Registruotis',
  msg_received_orders_tab: 'Gauti užsakymai',
  msg_payment_pay_with_selected_card: 'Mokėti naudojant pasirinktą kortelę',
  msg_payment_no_cards_yet: 'Mokėjimo kortelių neturite.',
  msg_payment_card_number_display_format: '•••• •••• •••• {{last4}}',
  msg_btn_delete_credit_card: 'Ištrinti kortelę',
  msg_payment_card_delete_success_modal_title_gerai: 'Kortelė ištrinta!',
  msg_payment_card_delete_success_modal_title: 'Kortelė ištrinta iš sąrašo',
  msg_payment_card_delete_modal_title: 'Ar tikrai norite pašalinti kortelę iš sąrašo?',
  msg_page_user: 'Mano nustatymai',
  msg_page_category: 'Kategorija',
  msg_page_register: 'Registruotis',
  msg_page_not_found: 'Puslapis nerastas!',
  msg_page_new_advert: 'Sukurti naują skelbimą',
  msg_page_my_orders: 'Užsakymai',
  msg_page_my_favourites: 'Mėgstamiausi skelbimai',
  msg_page_my_auctions: 'Mano aukcionai',
  msg_page_my_adverts: 'Mano skelbimai',
  msg_page_my_account: 'Mano paskyra',
  msg_page_marketplace: 'Prekyvietė',
  msg_page_login: 'Prisijungti',
  msg_page_landing: '„Sejico“ - Inovatyvi platforma vienijanti visus žemės ūkio dalyvius',
  msg_page_intro: '„Sejico“ - Inovatyvi platforma vienijanti visus žemės ūkio dalyvius',
  msg_page_go_to_new_auction: 'Sukurti aukcioną',
  msg_page_go_to_new_ad: 'Įkelti skelbimą',
  msg_page_forum: 'Klausk eksperto',
  msg_page_static: 'Statinis puslapis',
  msg_page_forgot_password: 'Pamiršai slaptažodį?',
  msg_page_faq_title: 'DUK',
  msg_page_contact: 'Kontaktai',
  msg_page_about: 'Apie mus',
  msg_page_company_orders: 'Užsakymai',
  msg_page_company_ads: 'Mano skelbimai',
  msg_page_calendar_title: 'Kalendorius',
  msg_page_auctions: 'Aukcionai',
  msg_page_advert_title: 'Skelbimas',
  msg_page_activity_title: 'Veikla',
  msg_order_summary_title_order_was_declined: 'Užsakymas buvo atmestas!',
  msg_order_summary_title_order_was_cancelled: 'Užsakymas atšauktas',
  msg_order_summary_title_order_was_accepted: 'Užsakymas patvirtintas!',
  msg_order_summary_subtitle_at_the_bottom:
    '*Apmokėjimas vyksta tarp kliento ir paslaugos/prekės tiekėjo ar pardavėjo pagal susitarimą.',
  msg_order_summary_start_date: 'Pradžia',
  msg_order_summary_price_ton: 'Preliminari kaina (eur/t)',
  msg_order_summary_price_night: 'Preliminari kaina (eur/d.)',
  msg_order_summary_price_meter: 'Preliminari kaina (eur/m)',
  msg_order_summary_price_liter: 'Preliminari kaina (eur/l)',
  msg_order_summary_price_kg: 'Preliminari kaina (eur/kg)',
  msg_order_summary_price_item: 'Užsakymo kaina',
  msg_order_summary_price_he: 'Kaina',
  msg_order_summary_price: 'Paslaugos kaina',
  msg_order_summary_phone_number: 'Tel. Nr.',
  msg_order_summary_order_was_finished: 'Užsakymas įvykdytas!',
  msg_order_summary_negotiable_price: 'Kaina sutartinė',
  msg_order_summary_location: 'Vieta',
  msg_order_summary_header_title: 'Užsakymo santrauka',
  msg_order_summary_free_item_price: 'Dovanoja',
  msg_order_summary_final_price: 'Galutinė kaina',
  msg_order_summary_end_date: 'Pabaiga',
  msg_order_summary_duration: 'Laikotarpis',
  msg_order_summary_amount: 'Kiekis',
  msg_orders_requested_orders: 'Išsiųsti',
  msg_orders_received_orders: 'Gauti',
  msg_orders_all_orders: 'Visi',
  msg_order_review_your_rating: 'Įvertinkite užsakymą',
  msg_order_review_form_placeholder: 'Palikite komentarą apie užsakymą, kuris bus matomas kitiems vartotojams.',
  msg_order_review_create: 'Įvertinkite užsakymą!',
  msg_order_payment_type_placeholder: 'Pasirinkite mokėjimo būdą',
  msg_order_payment_form_header: 'Mokėjimo kortelės informacija',
  msg_order_in_confirm_services_received: 'Užsakymas',
  msg_order_formulate_order: 'Užsakymo formavimas',
  msg_order_finish_title: 'Užsakymas atliktas!',
  msg_order_finish_subtitle: 'Paslaugos buvo suteiktos arba prekės buvo pristatytos.',
  msg_order_edit_order_success: 'Užsakymas sėkmingai pakeistas!',
  msg_order_edit_description: 'Čia galite palikti žinutę pardavėjui. Ji bus matoma tik patvirtinus užsakymą.',
  msg_order_delivery_price: 'Pristatymo kaina',
  msg_order_delivery_option: 'Pristatymas negalimas',
  msg_order_decline_title: 'Ar tikrai norite atmesti užsakymą?',
  msg_order_decline_subtitle: 'Atmestą užsakymą galėsite matyti gautų užsakymų sąraše.',
  msg_order_date_in_confirm_services_received: 'Užsakymo data',
  msg_order_create_review_success: 'Ačiū! Jūsų įvertinimas pateiktas!',
  msg_order_create_order_to: 'Data iki',
  msg_order_create_order_success: 'Užsakymas sėkmingai pateiktas!',
  msg_order_create_order_from: 'Data nuo',
  msg_order_delivery_option_with_price: 'Pristatymo kaina {{price}}',
  msg_order_create_order: 'Sukurti užsakymą',
  msg_order_create_item_amount: 'Nurodykite kiekį (vnt.)',
  msg_order_create_hectare_amount: 'Nurodykite sklypo plotą (ha)',
  msg_order_confirm_services_received_header: 'Patvirtinimas apie atliktą užsakymą',
  msg_order_choose_preliminary_dates: 'Pasirinkite preliminarias užsakymo atlikimo datas',
  msg_order_card_payment_success: 'Užsakymas sėkmingai apmokėtas!',
  msg_order_card_payment_form_submit: 'Pateikti',
  msg_order_card_payment_form_expiry_date_field: 'Kortelė galioja iki',
  msg_order_card_payment_form_cvc_code_field: 'CVC kodas',
  msg_order_card_payment_form_card_number_field: 'Kortelės nr.',
  msg_order_cancel_title: 'Ar tikrai norite atšaukti užsakymą?',
  msg_order_cancel_subtitle: 'Pasirinkite veiksmą žemiau',
  msg_order_advert_title_in_confirm_services_received: 'Skelbimo pavadinimas',
  msg_order_advert_summary_header_title: 'Skelbimo santrauka',
  msg_order_advert_summary_description_title: 'Aprašymas',
  msg_order_advert_company_in_confirm_services_received: 'Tiekėjas',
  msg_order_accept_title: 'Ar tikrai norite patvirtinti užsakymą?',
  msg_no_activity_found: 'Įrašų nėra',
  msg_navbar_icon_upload_ad: 'Įkelti skelbimą',
  msg_order_accept_subtitle: 'Užsakovas bus informuotas apie priimtą užsakymą.',
  msg_navbar_icon_profile: 'Profilis',
  msg_navbar_icon_my_orders: 'Užsakymai',
  msg_navbar_icon_my_ads: 'Skelbimai',
  msg_navbar_icon_forum: 'Klausk eksperto',
  msg_my_adverts_availability_schedule_page: 'Skelbimo tvarkaraštis',
  msg_my_adverts_availability_page_hero_title: 'Paslaugos prieinamumas',
  msg_my_adverts_availability_page_hero_subtitle: 'Čia galite matyti paslaugos/technikos prieinamumo tvarkaraštį.',
  msg_meter_amount: 'Nurodykite kiekį (m)',
  msg_login_form_login: 'Prisijungti',
  msg_login_form_forgot_password: 'Pamiršote slaptažodį?',
  msg_liter_amount: 'Kiekis (l)',
  msg_landing_page_competitive_advantage_title_3: 'Bendradarbiavimo erdvė',
  msg_landing_page_competitive_advantage_title_2: 'Mobili aplikacija',
  msg_landing_page_competitive_advantage_title_1: 'Skelbimų ir užsakymų valdymo sistema',
  msg_landing_page_competitive_advantage_description_1:
    'Patikima, patogi ir skaidri skelbimų ir užsakymų valdymo sistema, suteikianti galimybę bendrauti tarpusavyje bei skelbti apie savo prekes ir paslaugas.',
  msg_landing_page_competitive_advantage_description_2:
    'Šiuo metu kuriama mobili aplikacija, kurios pagalba galėsite patogiai valdyti savo skelbimus, užsakymus, bendrauti su tiekėjais ar klientais, diskutuoti forume.',
  msg_landing_page_competitive_advantage_description_3:
    'Sejico - erdvė, skirta dalintis idėjomis, patarimais bei diskutuoti apie aktualias žemės ūkio problemas.',
  msg_landing_page_competitive_advantage_section_title: 'Kodėl Sejico?',
  msg_landing_featured_auctions_title: 'Rekomenduojami aukcionai',
  msg_landing_featured_adverts_title: 'Rekomenduojami skelbimai',
  msg_landing_topical_adverts_title: 'Aktualūs skelbimai',
  msg_label_week: 'Savaitė',
  msg_label_vegetables: 'Daržovės',
  msg_label_username: 'Vartotojo vardas',
  msg_label_upload_main: 'Įkelti pagrindinę nuotrauką',
  msg_label_upload_file_description:
    'Čia galite įkelti pagrindinę nuotrauką. Papildomai galite įkelti dar 4 nuotraukas.',
  msg_label_upload_file: 'Įkelti nuotrauką',
  msg_label_uploaded_image: 'Nuotrauka įkelta.',
  msg_label_upload: 'Įkelti nuotrauką',
  msg_label_unfinished: 'Nebaigtus',
  msg_label_telescopic_loader_rent: 'Teleskopinių krautuvų nuoma',
  msg_label_tillage: 'Arimas',
  msg_label_to: 'Iki',
  msg_label_tractors_rent: 'Traktorių nuoma',
  msg_label_trade: 'Prekyba',
  msg_label_transportation_services: 'Transportavimo paslaugos',
  msg_label_targeted: 'Tiksliniai',
  msg_label_status_unfinished: 'Nebaigtas',
  msg_label_status_payment_pending: 'Neapmokėtas',
  msg_label_status_finished: 'Įvykdytas',
  msg_label_status_expired: 'Nebegaliojantis',
  msg_label_status_ended: 'Baigtas',
  msg_label_status_declined: 'Atmestas',
  msg_label_status_created: 'Nepatvirtintas',
  msg_label_status_canceled: 'Atšauktas',
  msg_order_payment_pending_finish: 'Užsakymas patvirtintas',
  msg_label_status_active: 'Aktyvus',
  msg_label_status_accepted: 'Patvirtintas',
  msg_label_sprayer_rent: 'Purkštuvų nuoma',
  msg_label_soil_preparation_for_sowing: 'Dirvos ruošimas sėjai',
  msg_label_service: 'Paslauga',
  msg_label_sent_orders: 'Išsiųsti',
  msg_label_sell_tractors: 'Traktoriai',
  msg_label_seller: 'Pardavėjas',
  msg_label_sell_agregate: 'Agregatai',
  msg_label_see_more_auctions: 'Rodyti daugiau aukcionų',
  msg_label_see_more_adverts: 'Rodyti daugiau skelbimų',
  msg_label_seedlings: 'Sodinukai',
  msg_label_seeder_rent: 'Sėjamųjų nuoma',
  msg_label_section_related_adverts_title: 'Panašūs skelbimai',
  msg_label_section_last_previewed_adverts_title: 'Peržiūrėti skelbimai',
  msg_label_section_featured_adverts_title: 'Rekomenduojami skelbimai',
  msg_label_scraper_rent: 'Skutiklių nuoma',
  msg_label_schedule_display: 'Rodyti kalendorių',
  msg_label_sale: 'Siūlo',
  msg_label_return_back: 'Grįžti',
  msg_label_rent: 'Nuoma',
  msg_label_register: 'Registruotis',
  msg_label_received_orders: 'Gauti',
  msg_label_real_estate: 'Nekilnojamas Turtas',
  msg_label_purchase: 'Ieško',
  msg_label_plows_rent: 'Plūgų nuoma',
  msg_label_pesticide_spraying: 'Pesticidų purškimas',
  msg_label_payment_pending: 'Laukia apmokėjimo',
  msg_label_password: 'Slaptažodis',
  msg_label_other_services: 'Kitos paslaugos',
  msg_label_other_machinery_rent: 'Kitos technikos nuoma',
  msg_label_other: 'Kita',
  msg_label_order_unfinished: 'Užsakymas nebaigtas!',
  msg_label_order_type_requested: 'Pateiktas',
  msg_label_order_type_received: 'Gautas',
  msg_label_order_status_created: 'Nepatvirtintas',
  msg_label_order_status_accepted: 'Patvirtintas',
  msg_label_order_needs_payment: 'Užsakymas laukia apmokėjimo!',
  msg_label_order_item_status: 'Statusas',
  msg_label_order_item_seller: 'Pardavėjas',
  msg_label_order_item_order_price: 'Kaina',
  msg_label_order_item_order_created_at: 'Užsakymas sukurtas',
  msg_label_order_item_buyer: 'Pirkėjas',
  msg_label_order_finished: 'Užsakymas įvykdytas!',
  msg_label_order_expired: 'Užsakymas nebegalioja!',
  msg_label_order_ended: 'Užsakymas baigtas!',
  msg_label_order_declined: 'Užsakymas atmestas!',
  msg_label_order_created: 'Gavote naują užsakymą!',
  msg_label_order_canceled: 'Užsakymas atšauktas!',
  msg_label_order_active: 'Užsakymas aktyvus!',
  msg_label_order_accepted: 'Užsakymas patvirtintas!',
  msg_label_or: 'ARBA',
  msg_label_no_results: 'Rezultatų nėra',
  msg_label_no_payment_cards: 'Neturite išsaugotų kortelių.',
  msg_label_new_chat_message: 'Nauja žinutė.',
  msg_label_natural: 'Asmuo',
  msg_label_mushrooms: 'Grybai',
  msg_label_month: 'Mėnuo',
  msg_label_milling: 'Frezavimas',
  msg_label_milk_products: 'Pieno produktai',
  msg_label_meta_title: 'Sejico.lt',
  msg_label_meta_description: '„Sejico“ - Inovatyvi platforma vienijanti visus žemės ūkio dalyvius',
  msg_label_meat_products: 'Mėsos produktai',
  msg_label_manure_equipment_rent: 'Mėšlo tvarkymo įranga',
  msg_label_mandatory: 'Būtinieji',
  msg_label_machinery_repair: 'Technikos remontas',
  msg_label_machinery_rent: 'Technikos padargų nuoma',
  msg_label_login: 'Prisijungti',
  msg_label_list_view: 'Sąrašas',
  msg_label_legal: 'Įmonė',
  msg_label_last_previewed_adverts_no_adverts: 'Peržiūrėtų skelbimų neturite.',
  msg_label_job_ads: 'Siūlo/Ieško darbo',
  msg_label_good: 'Prekė',
  msg_label_grains_products: 'Grūdų produktai',
  msg_label_grid_view: 'Tinklelis',
  msg_label_hay_baling: 'Šieno rulonavimas',
  msg_label_hay_rolls: 'Šienainio rulonai',
  msg_label_herbs: 'Vaistažolės',
  msg_label_implements: 'Padargai',
  msg_label_invoice_file: 'Išrašas',
  msg_label_invoice_number: 'Išrašo numeris',
  msg_label_eur_per_kilogram: 'eur/kg',
  msg_label_eur_per_liter: 'eur/l',
  msg_label_eur_per_meter: 'eur/m',
  msg_label_eur_per_night: 'eur/d.',
  msg_label_eur_per_ton: 'eur/t',
  msg_label_expired: 'Nebegaliojančius',
  msg_label_farm: 'Ūkis',
  msg_label_favorite: 'Įsiminti',
  msg_label_favorited: 'Įsimintas',
  msg_label_featured_auctions_no_auctions: 'Šiuo metu nėra aktyvių aukcionų.',
  msg_label_finished: 'Įvykdytus',
  msg_label_fish_products: 'Žuvies produktai',
  msg_label_forum_thread_created: 'Sukurta {{date}}',
  msg_label_from: 'Nuo',
  msg_label_fruits: 'Vaisiai',
  msg_label_fruit_trees: 'Vaismedžiai',
  msg_label_functional: 'Funkciniai',
  msg_btn_user_tour_next: 'Kitas',
  msg_label_eur_per_item: 'eur/vnt',
  msg_label_eur_per_hour: 'eur/val.',
  msg_label_eur_per_hectare: 'eur/ha',
  msg_label_eur: 'Eur',
  msg_label_ended: 'Baigtus',
  msg_label_email: 'El. paštas',
  msg_label_edit_advert_title: 'Skelbimas pakeistas!',
  msg_label_edit_advert_subtitle: 'Skelbimo pakeitimai išsaugoti.',
  msg_label_default_select_placeholder: 'Pasirinkite',
  msg_label_declined: 'Atmestus',
  msg_label_day: 'Diena',
  msg_label_cultivators_rent: 'Kultivatorių nuoma',
  msg_label_cultivation: 'Kultivavimas',
  msg_label_credit_card_payment_type: 'Kredito/Debeto kortelė',
  msg_label_credit_card: 'Kredito/Debeto kortelė',
  msg_label_created: 'Nepatvirtintus',
  msg_label_create_company_hectare: 'Ūkio plotas (ha)',
  msg_label_create_company_cattle: 'Galvijų skaičius',
  msg_label_construction_services: 'Statybų paslaugos',
  msg_label_company_form_vat_code: 'PVM mokėtojo kodas',
  msg_label_company_form_phone: 'Tel. nr.',
  msg_label_company_form_name: 'Pavadinimas',
  msg_label_company_form_hectares: 'Ūkio plotas',
  msg_label_company_form_farm_code: 'Ūkio kodas',
  msg_label_company_form_farm_activity: 'Pagrindinė veikla',
  msg_label_company_form_company_code: 'Įmonės kodas',
  msg_label_company_created: 'Ūkis sukurtas!',
  msg_label_combine_rent: 'Kombainų nuoma',
  msg_label_canceled: 'Atšauktus',
  msg_label_buyer: 'Pirkėjas',
  msg_label_billing_info_form_vat_code: 'PVM mokėtojo kodas (jei taikomas)',
  msg_label_billing_info_form_name: 'Pavadinimas',
  msg_label_billing_info_form_company_code: 'Įmonės/Asmens/Individualios veiklos pažymos nr.',
  msg_label_billing_info_form_adress: 'Adresas',
  msg_label_berries: 'Uogos',
  msg_label_beekeping_products: 'Bitininkystės produktai',
  msg_label_auction_new_bid_inform_creator: 'Aukcione pateiktas naujas pasiūlymas!',
  msg_calendar_day: 'Diena',
  msg_calendar_event: 'Įrašas',
  msg_label_auction_new_bid_inform_bidders: 'Aukcione pateiktas naujas pasiūlymas!',
  msg_label_auction_ended_inform_creator: 'Aukcionas baigėsi!',
  msg_label_auction_ended_inform_bidders: 'Aukcionas baigėsi!',
  msg_label_auction_declined: 'Aukcionas atmestas!',
  msg_label_auction_approved: 'Aukcionas patvirtintas!',
  msg_label_analytics: 'Analitiniai',
  msg_label_all_orders: 'Visi',
  msg_label_agro_services: 'Žemės ūkio paslaugos',
  msg_label_agro_machinery: 'ŽŪ technika',
  msg_label_advert_id_label: 'Skelbimo ID',
  msg_label_ad_status_inactive: 'Neaktyvus skelbimas',
  msg_label_ad_status_active: 'Aktyvus skelbimas',
  msg_label_active: 'Aktyvius',
  msg_label_accepted: 'Patvirtintus',
  msg_label_agreed_price: 'Mokėjimas pagal susitarimą',
  msg_label_credit_card_deletion_info: 'Jeigu ištrinsite kortelę, ji neatsiras šalia jūsų mokėjimo būdų',
  msg_label_add_payment_method: 'PRIDĖTI MOKĖJIMO BŪDĄ',
  msg_label_add_card: 'Pridėti kortelę',
  msg_kilogram_amount: 'Nurodykite kiekį (kg)',
  msg_item_amount: 'Pageidaujamas kiekis',
  msg_label_advert_tour_ad_step2:
    'Čia galite nurodyti užsakymo kiekį ar trukmę bei pasirinkti pageidaujamą apmokėjimo būdą.',
  msg_label_advert_tour_ad_step3: 'Sistema automatiškai apskaičiuoja preliminarią užsakymo kainą.',
  msg_label_advert_tour_ad_step4:
    'Čia galite palikti žinutę tiekėjui, kurią jis matys patvirtinęs užsakymą. Ir spustelėkite "Pateikti", kad užsakymas būtų pateiktas.',
  msg_label_advert_tour_ad_step1: 'Šiame lange matote skelbimo informaciją.',
  msg_invite_user_success: 'Vartotojas pakviestas!',
  msg_invite_user_modal_title: 'Norėdami pakviesti vartotoją, nurodykite jo el. pašto adresą.',
  msg_helper_text_company_form_name_natural:
    'Jeigu sąskaita reikalinga fiziniam asmeniui, nurodykite vardą ir pavardę.',
  msg_helper_text_company_form_name_company:
    'Nurodykite, įmonės ar asmens, kuriai(-am) bus išrašoma sąskaita, pavadinimą.',
  msg_helper_text_company_form_name: 'Nurodykite pavadinimą, kurį skelbime matys kiti vartotojai.',
  msg_forum_threads_table_subtopic: 'Tema',
  msg_forum_threads_table_last_comment: 'Paskutinis pasisakė',
  msg_forum_threads_table_comments: 'Komentarai',
  msg_forum_threads_table_comment_count: 'Pasisakymai',
  msg_forum_threads_table_author: 'Autorius',
  msg_forum_threads_page_title: 'Forumo tema',
  msg_filters_label_categories: 'Kategorijos',
  msg_filters_label_filters: 'Filtrai',
  msg_filters_price_range_from_placeholder: 'Nuo',
  msg_filters_price_range_to_placeholder: 'Iki',
  msg_forgot_password_sent_sub_title: 'Pasitikrinte savo el. pašto dėžutę.',
  msg_forgot_password_sent_title: 'Slaptažodis išsiųstas!',
  msg_forgot_password_sub_title: 'Nurodykite savo el. paštą, kuriuo bus išsiųstas slaptažodis.',
  msg_forgot_password_title: 'Pamiršote slaptažodį?',
  msg_forum_replay_to_message: 'Cituoti',
  msg_forum_thread_no_records_message: 'Komentarų nėra',
  msg_forum_thread_page_title: 'Forumo temos pranešimai',
  msg_filters_distance: 'Atstumas',
  msg_filters_field_group_label_add_type: 'Skelbimo veiksmas',
  msg_filters_field_group_label_price_type: 'Kainos tipas',
  msg_filters_field_group_label_service_type: 'Skelbimo tipas',
  msg_filters_fields_accepted_events: 'Aktyvūs',
  msg_filters_fields_adType_label_all: 'Visi',
  msg_filters_fields_adType_label_sale: 'Siūlo',
  msg_filters_fields_adType_label_purchase: 'Ieško',
  msg_filters_fields_ad_service_type_label_all: 'Visi',
  msg_filters_fields_ad_service_type_label_good: 'Prekė',
  msg_filters_fields_ad_service_type_label_service: 'Paslauga',
  msg_filters_fields_adType_label_offers: 'Siūlo',
  msg_filters_fields_adType_label_search: 'Ieško',
  msg_filters_fields_custom_events: 'Kiti įvykiai',
  msg_filters_fields_pending_events: 'Laukia patvirtinimo',
  msg_filters_fields_pricing_label_per_ha: 'eur/ha',
  msg_filters_fields_pricing_label_per_hour: 'eur/val.',
  msg_filters_fields_pricing_label_per_item: 'eur/vnt.',
  msg_filters_fields_pricing_label_per_kilogram: 'eur/kg',
  msg_filters_fields_pricing_label_per_liter: 'eur/l',
  msg_filters_fields_pricing_label_per_meter: 'eur/m',
  msg_filters_fields_pricing_label_per_night: 'eur/d.',
  msg_filters_fields_pricing_label_per_ton: 'eur/t',
  msg_btn_create_invoice: 'Sukurti sąskaitą',
  msg_filter_field_group_label_price_range: 'Kaina',
  msg_field_size: 'Plotas (ha)',
  msg_favorite_no_adverts_found: 'Jūs neturite mėgstamų skelbimų.',
  msg_favorite_adverts_header_name: 'Pavadinimas',
  msg_favorite_adverts_header_farm_name: 'Tiekėjas',
  msg_favorite_adverts_header_action: 'Veiksmas',
  msg_faq_contact_us:
    'Neradai atsakymo? Susisiek su mumis <a href="mailto:info@sejico.lt" ><span> info@sejico.lt </span></a>',
  msg_error_unexpected: 'Įvyko netikėta klaida',
  msg_error_wrong_company_input: 'Neteisingai įvesta informacija!',
  msg_error_company_phone_missing: 'Neįvestas įmonės telefono numeris!',
  msg_internal_server_error: 'Įvyko klaida. Mėginkite dar kartą',
  msg_error_user_with_this_email_already_exists: 'Toks el. paštas jau užregistruotas!',
  msg_error_username_exists: 'Toks vartotojo vardas jau egzistuoja!',
  msg_error_time_is_before_start_time: 'Pabaigos laikas negali būti ankstesnis už pradžios laiką!',
  msg_error_this_password_is_too_common: 'Slaptažodis nesaugus. Pasirinkite kitą slaptažodį.',
  msg_error_smaller_number: 'Kaina turi būti aukštesnė už paskutinę nurodytą kainą.',
  msg_error_selected_day_is_before_today: 'Pasirinkta data negali būti ankstesnė nei šios dienos data.',
  msg_error_passwords_are_equal: 'Slaptažodžiai negali sutapti',
  msg_error_password_format: 'Slaptažodis turi turėti bent vieną skaičių ir būti ne trumpesnis nei 5 simboliai.',
  msg_error_text_contains_number: 'Jūsų tekste neturėtų būti telefono numerio',
  msg_error_over_max_size: 'Ūkio plotas gali būti ne didesnis kaip {{maxSize}} ha',
  msg_error_over_max_price_amount: 'Jūsų nurodyta kaina per didelė, maksimali kaina - 21 474 836 Eur',
  msg_error_over_max_length: 'Galite įvesti {{maxLen}} simbolius(-ių)',
  msg_error_over_max_fraction_digit_length: 'Galite įvesti daugiausiai {{maxLen}} skaitmenį(-is) po taško',
  msg_error_over_max_amount: 'Maksimalus kiekis {{maxAmount}}',
  msg_error_second_number_should_be_grater: 'Antrasis skaičius turi būti didesnis',
  msg_error_order_only_for_logged_in_users:
    '<a href="/authentication/login"><h4>Prisijunkite,</h4></a><span>norėdami pateikti užsakymą.</span>',
  msg_error_order_dates_out_of_schedule: 'Netinkamos datos! Patikrinkite tvarkaraštį!',
  msg_error_order_date_diff_too_short: 'Minimalus pasirinkimas - bent 1 d.',
  msg_company_users_list_title: 'Varotojų sąrašas',
  msg_error_order_date_diff_invalid: 'Minimalus užsakymo laikas - 3 val.',
  msg_error_only_numbers_allowed: 'Čia galima įvesti tik skaičius!',
  msg_error_not_whole_number: 'Galite įvesti tik sveikus skaičius.',
  msg_error_min_text_length: 'Norėdami ieškoti, įveskite bent {{minLen}} simbolius.',
  msg_error_min_amount: 'Įveskite skaičių didesnį už 0.',
  msg_error_invited_user_already_exists_in_company: 'Šis vartotojas jau yra jūsų sąraše.',
  msg_error_invalid_username: 'Vartotojo vardą turi sudaryti bent 4 simboliai.',
  msg_error_invalid_price_format: 'Čia galima įvesti tik skaičius!',
  msg_error_invalid_phone: 'Neteisingas telefono numeris.',
  msg_error_invalid_field: 'Neteisingas formatas. Įveskite +370...',
  msg_error_invalid_email: 'Neteisingas el. pašto adresas',
  msg_error_invalid_date: 'Neteisingai įvesta data',
  msg_error_invalid_credentials: 'Neteisingi prisijungimo duomenys',
  msg_error_invalid_amount_value: 'Skaičius turi būti didesnis už 0.',
  msg_error_invalid_amount: 'Netinkama suma!',
  msg_error_invalid_value: 'Netinkama vertė.',
  msg_error_invalid_advert_categories: 'Pasrinkite prekės arba paslaugos kategoriją!',
  msg_error_incorrect_password: 'Neteisingas slaptažodis.',
  msg_error_image_size_too_large: 'Nuotrauka negali būti didesnė nei 5MB.',
  msg_error_greater_number: 'Kaina turi būti mažesnė, nei paskutinė siūloma kaina.',
  msg_error_field_required: 'Privalomas laukas',
  msg_error_error_occurred: 'Įvyko klaida. Pekraukite puslapį ir mėginkite dar kartą',
  msg_error_end_time_is_equal_start_time: 'Pradžios laikas turi būti ankstesnis už pabaigos',
  msg_error_end_time_is_before_start_time: 'Pabaigos data turi būti vėlesnė už pradžios datą',
  msg_error_dates_is_overlapping: 'Datos persidengia!',
  msg_error_dates_difference_too_small: 'Mažiausia trukmė - 3 val.',
  msg_error_auction_only_for_logged_in_users:
    '<a href="/authentication/login"><h4>Prisijunkite,</h4></a><span>norėdami dalyvauti aukcione.</span>',
  msg_error_auction_create_only_for_logged_in_users: 'Prisijunkite, norėdami sukurti aukcioną.',
  msg_error_categories_details_wrong_format: 'Neteisingas kategorijų informacijos formatas',
  msg_custom_fields_unexpected_error_unknown: 'Neteisingai įvesta informacija - netinkamas lauko formatas.',
  msg_custom_fields_unexpected_error_unexpected_field:
    'Neteisingai įvesta informacija - šioje kategorijoje šis laukas nenumatytas.',
  msg_custom_fields_unexpected_error_should_be_empty_because_no_schema:
    'Neteisingai įvesta informacija - šioje kategorijoje papildomi laukai nenumatyti.',
  msg_custom_fields_unexpected_error_invalid_string: 'Neteisingai įvesta informacija - čia galima įvesti tik tekstą.',
  msg_custom_fields_unexpected_error_invalid_boolean:
    'Neteisingai įvesta informacija - šio lauko reikšmės yra tik "taip" ir "ne".',
  msg_custom_fields_error_required_field: 'Privalomas laukas.',
  msg_custom_fields_error_more_than_max: 'Reikšmė negali būti mažesnė nei {{limit}}.',
  msg_custom_fields_error_more_than_exclusive_max: 'Reikšmė turi būti mažesnė nei {{limit}}.',
  msg_custom_fields_error_min_length: 'Įveskite bent {{limit}} simbolius.',
  msg_custom_fields_error_max_length: 'Galite įvesti ne daugiau nei {{limit}} simbolius(-ių).',
  msg_label_order_status_active: 'Aktyvus',
  msg_custom_fields_error_less_than_min: 'Reikšmė negali būti mažesnė nei {{limit}}.',
  msg_custom_fields_error_less_than_exclusive_min: 'Reikšmė turi būti didesnė nei {{limit}}.',
  msg_error_custom_fields_invalid_integer: 'Galite įvesti tik sveikus skaičius.',
  msg_custom_fields_error_invalid_floating_point: 'Galite įvesti tik skaičius.',
  msg_create_thread_title_placeholder: 'Pavadinimas',
  msg_create_thread_header_title: 'Nauja tema',
  msg_create_thread_header_subtitle:
    'Forumas yra vieta, kur mūsų nariai susirenka bendrauti ir dalintis patirtimi bei patarimais. Prieš kurdamas temą, įsitikink, kad tokia tema dar nėra sukurta. Tai padės nariams greičiau rasti reikiamas diskusijas.',
  msg_create_thread_content_placeholder: 'Tekstas',
  msg_create_order_ton_amount_placeholder: 'Kiekis (t)',
  msg_create_order_meter_amount_placeholder: 'Kiekis (m)',
  msg_create_order_liter_amount_placeholder: 'Nurodykite pageidaujamą kiekį (l)',
  msg_create_order_kilogram_amount: 'Kiekis (kg)',
  msg_create_order_hours_placeholder: 'val.',
  msg_create_order_date_to_placeholder: 'Data',
  msg_create_order_date_from_placeholder: 'Data',
  msg_create_forum_thread_success_title: 'Tema sukurta',
  msg_create_forum_thread_success_subtitle: 'Dėkojame už temą ir linkime įdomios diskusijos!',
  msg_create_forum_thread_success_button: 'Uždaryti',
  msg_create_forum_thread_page_title: 'Sukurkite forumo temą',
  msg_create_company_skip_this_step: 'Praleisti šį žingsnį',
  msg_create_company_profile_title: 'Sukurkite ūkį!',
  msg_create_company_profile_subtitle: 'Ūkio informacija reikalinga norint pateikti užsakymus.',
  msg_create_company_phone: 'Tel. nr.',
  msg_create_company_p_entity_code: 'Pasirinkite ūkio veiklą.',
  msg_create_company_name: 'Nurodykite ūkio pavadinimą',
  msg_create_company_hectare: 'Ūkio plotas (ha)',
  msg_create_company_delete_your_account: 'Norėdami ištrinti ūkį, susisiekite su mumis',
  msg_create_company_continue_creation_title: 'Sveikiname! Registracija sėkminga!',
  msg_create_company_continue_creation_subtitle: 'Galite pradėti naudotis Sejico!',
  msg_calendar_event_update_success: 'Įrašas pakeistas!',
  msg_calendar_all_day: 'Diena',
  msg_cookies_text:
    'Mūsų interneto svetainėje yra naudojami slapukai (angl. cookies). Slapukai užtikrina sklandų svetainės veikimą bei jo tobulinimą, todėl būtinieji slapukai (techniniai, funkciniai bei analitiniai) yra įdiegiami automatiškai. Paspaudę „sutinku“ Jūs sutinkate su slapukų įdiegimu ir naudojimu. Savo sutikimą galėsite atšaukti bet kuriuo metu, pakeisdami savo interneto naršyklės nustatymus ir ištrindami įrašytus slapukus. Daugiau informacijos rasite <a href="/page/privatumo-politika" target="_blank"><span>„Privatumo Politikoje“</span></a>.',
  msg_cookies_settings: 'Slapukų nustatymai',
  msg_cookies_modal_text:
    'Apie slapukus daugiau galite sužinoti mūsų <a href="/page/privatumo-politika" target="_blank">privatumo politikoje</a>.',
  msg_cookies_modal_header: 'Slapukų nustatymai',
  msg_cookies_header: 'Sejico naudojami slapukai',
  msg_contact_page_mobile_label: 'Susisiekite telefonu: <p><a href="tel:+37060020585">tel:+37060020585</a></p>',
  msg_contact_page_heading: 'Susisiek su mumis',
  msg_contact_page_facebook_label:
    'Sek naujienas ir bendrauk mūsų <a href="https://www.facebook.com/Sejico-102719424809555" target="_blank"> facebook paskyroje </a>',
  msg_contact_page_email_label: 'Bendri klausimai: <p><a href="mailto:info@sejico.lt">info@sejico.lt</a></p>',
  msg_contact_page_description: 'Mums svarbūs mūsų bendruomenės nariai, todėl esame čia, kad jums padėtume!',
  msg_billing_info_updated_title_edit: 'Informacija sėkmingai atnaujinta!',
  msg_billing_info_updated_subtitle_edit: 'Ši informacija bus naudojama generuojant sąskaitą.',
  msg_billing_info_updated_title_create: 'Informacija išdaugota!',
  msg_billing_info_updated_subtitle_create: 'Ši informacija bus naudojama generuojant jūsų sąskaitą.',
  msg_company_users_info_tab: 'Ūkio dalyviai',
  msg_company_updated_title: 'Informacija sėkmingai atnaujinta!',
  msg_company_updated_subtitle: 'Pakeitimai išsaugoti.',
  msg_company_reviews: 'Vartotojo vertinimai',
  msg_company_orders_page: 'Mano užsakymai',
  msg_company_orders_header_client: 'Užsakovas',
  msg_company_orders_filter_all: 'Visus',
  msg_company_no_orders_sent: 'Užsakymų nėra',
  msg_company_no_orders_requested: 'Užsakymų nėra',
  msg_company_no_auctions: 'Jūs dar nedalyvaujate ir nesukūrėte aukciono.',
  msg_company_my_company_reviews: 'Ūkio įvertinimas',
  msg_company_joined_label: 'Registracijos data',
  msg_company_form_modal_title: 'Naujo ūkio informacija',
  msg_company_empty_reviews: 'Vertinimų nėra.',
  msg_company_edit_action: 'Redaguoti',
  msg_company_customer_support_email: 'info@sejico.lt',
  msg_company_calendar_action: 'Kalendorius',
  msg_company_advert_is_hidden: 'Skelbimas paslėptas!',
  msg_company_advert_invisible_status_title: 'Paspauskite, norėdami paslėpti skelbimą.',
  msg_company_advert_invisible_status_subtitle:
    'Skelbimas bus matomas tik jums, tačiau bet kada galite jį vėl padaryti aktyvų.',
  msg_company_ads_visibility_title: 'Paspauskite, norėdami, kad skelbimas būtų matomas.',
  msg_company_ads_visibility_description:
    'Aktyvus skelbimas bus matomas visiems vartotojams. Skelbimą visada galite paslėpti.',
  msg_company_ads_header_status: 'Skelbimo būsena',
  msg_join_company_title: 'Prisijunkite prie bendro ūkio!',
  msg_company_ads_header_stats: 'Skelbimo peržiūros',
  msg_company_update_advert_top_title: 'Redaguoti skelbimą',
  msg_company_preview_action: 'Peržiūrėti skelbimą',
  msg_company_advert_is_visible: 'Skelbimas matomas!',
  msg_company_empty_ads: 'Skelbimų neturite.',
  msg_company_ads_header_price: 'Skelbimo kaina',
  msg_company_ads_header_name: 'Skelbimo pavadinimas',
  msg_company_ads_header_actions: 'Skelbimo veiksmas',
  msg_company_ads_filter_placeholder: 'Rodyti',
  msg_company_ads_filter_inactive: 'Neaktyvius',
  msg_company_ads_filter_all: 'Visus',
  msg_company_ads_filter_active: 'Aktyvius',
  msg_company_active_ads: 'Aktyvūs skelbimai',
  msg_change_username_success: 'Vartotojo vardas pakeistas!',
  msg_change_username_subtitle: 'Jūsų vartotojo vardas',
  msg_change_username_new_username_placeholder: 'Įveskite naują vartotojo vardą',
  msg_change_username_heading: 'Vartotojo vardo keitimas',
  msg_change_password_success: 'Slaptažodis pakeistas!',
  msg_change_password_subheading: 'Jūsų el. pašto adresas',
  msg_change_password_old_password_placeholder: 'Įveskite senąjį slaptažodį',
  msg_change_password_new_password_placeholder: 'Įveskite naują slaptažodį',
  msg_calendar_no_events_in_range: 'Įvykių nerasta.',
  msg_calendar_previous: 'Atgal',
  msg_calendar_show_more: 'Rodyti daugiau',
  msg_calendar_time: 'Laikas',
  msg_calendar_title: 'Skelbimai',
  msg_categories_btn: 'Kategorijos',
  msg_calendar_today: 'Šiandien',
  msg_calendar_tomorrow: 'Rytoj',
  msg_calendar_week: 'Savaitė',
  msg_calendar_work_week: 'Darbo savaitė',
  msg_calendar_yesterday: 'Vakar',
  msg_change_password_heading: 'Slaptažodžio keitimas',
  msg_calendar_event_description: 'Aprašymas',
  msg_calendar_event_edit: 'Išsaugoti',
  msg_calendar_event_end_date: 'Pabaigos data',
  msg_calendar_event_end_time: 'Pabaigos laikas',
  msg_calendar_event_modal_edit_title: 'Redaguoti įrašą',
  msg_calendar_event_modal_title: 'Naujas įrašas',
  msg_calendar_event_start_date: 'Pradžios data',
  msg_calendar_event_start_time: 'Pradžios laikas',
  msg_calendar_event_title: 'Įrašo pavadinimas',
  msg_calendar_month: 'Mėnuo',
  msg_calendar_event_cancel_changes: 'Atšaukti',
  msg_calendar_event_create_success: 'Įrašas sukurtas!',
  msg_calendar_event_default_title: 'Įrašas',
  msg_btn_make_company_active: 'Padaryti ūkį aktyviu',
  msg_btn_modal_close: 'Uždaryti',
  msg_btn_not_found: 'Eiti į prekyvietę',
  msg_btn_ok: 'Gerai',
  msg_btn_order_decline: 'Atmesti',
  msg_btn_order_edit_accept: 'Patvirtinti',
  msg_btn_order_edit_cancel: 'Atšaukti',
  msg_btn_order_finish: 'Užbaigti užsakymą',
  msg_btn_order_no: 'Užsakymas nebuvo atliktas',
  msg_btn_preview_add: 'Peržiūrėti',
  msg_btn_preview_in_map: 'Rodyti žemėlapyje',
  msg_btn_search: 'Ieškoti',
  msg_btn_send: 'Siųsti',
  msg_btn_slider_cta: 'Peržiūrėkite pasiūlymus',
  msg_btn_submit: 'Pateikti',
  msg_btn_submit_new_thread: 'Paskelbti',
  msg_btn_thread_reply_success_button: 'Uždaryti',
  msg_btn_update_advert_form_submit: 'Išsaugoti pakeitimus',
  msg_btn_update_form: 'Pakeisti informaciją',
  msg_btn_verification: 'Gerai',
  msg_btn_view_company_order: 'Peržiūrėti',
  msg_calendar_add_custom_event: 'Sukurti įrašą',
  msg_calendar_agenda: 'Planas',
  msg_btn_logout: 'Atsijungti',
  msg_btn_login_google: 'Google',
  msg_btn_login_fb: 'Facebook',
  msg_btn_invite_user_modal: 'Pakviesti',
  msg_btn_invite_user: 'Pakviesti vartotoją',
  msg_btn_go_back: 'Grįžti',
  msg_btn_filters_modal_search: 'Ieškoti',
  msg_btn_filters_modal: 'Filtrai',
  msg_btn_fetch_more_items: 'Rodyti daugiau',
  msg_btn_edit_order: 'Keisti užsakymą',
  msg_btn_edit_advert_modal: 'Gerai',
  msg_btn_edit_advert: 'Redaguoti skelbimą',
  msg_btn_create_forum_thread: 'Užduok klausimą',
  msg_btn_ask_experts_button: 'Mūsų ekspertai',
  msg_experts_page_title: 'Mūsų ekspertai',
  msg_btn_done: 'Uždaryti',
  msg_btn_create_company: 'Sukurti ūkį',
  msg_btn_create_advert_form_submit: 'Sukurti skelbimą',
  msg_btn_company_orders_go_to_marketplace: 'Eiti į prekyvietę',
  msg_btn_continue: 'Tęsti',
  msg_btn_continue_company_creation: 'Pradėti',
  msg_btn_cookies_accept: 'Sutinku',
  msg_btn_cookies_accept_all: 'Patvirtinti visus slapukus',
  msg_btn_change_order_status_finished: 'Patvirtinu užsakymo atlikimą',
  msg_btn_cookies_modal_btn_accept: 'Išsaugoti slapukų nustatymus',
  msg_btn_create_additional_company: 'Sukurti naują ūkį',
  msg_btn_accept_order: 'Patvirtinti užsakymą',
  msg_btn_add_card_success: 'Kortelė pridėta!',
  msg_btn_add_image: 'Įkelti nuotrauką',
  msg_btn_add_new_add: 'Įkelti naują skelbimą',
  msg_btn_add_schedule: 'Pridėti laiką',
  msg_btn_auction_created_modal: 'Sukurti naują aukcioną',
  msg_btn_cancel: 'Atšaukti',
  msg_btn_cancel_action: 'Grįžti',
  msg_btn_cancel_order: 'Atšaukti užsakymą',
  msg_btn_change: 'Pakeisti',
  msg_auctions_filter_value_label: 'Filtras',
  msg_auctions_participating_tab: 'Dalyvauju',
  msg_auctions_sort_by_expiration_date: 'Mažiausiai laiko liko',
  msg_auctions_sort_by_expiration_date_desc: 'Daugiausiai laiko liko',
  msg_auction_starting_price: 'Pradinė kaina',
  msg_auction_total_bids: 'Dalyvauja',
  msg_auction_waiting_for_approval_status: 'Nepatvirtintas',
  msg_auction_winner_information_text: 'laimėjo aukcioną. Galutinė kaina:',
  msg_billing_info_updated_subtitle: 'Sąskaita bus sugeneruota pagal jūsų išsaugotą informaciją.',
  msg_billing_info_updated_title: 'Sąskaitos informacija sėkmingai pakeista!',
  msg_auction_create_save_auction_btn: 'Sukurti aukcioną',
  msg_auction_create_title_field_placeholder: 'Pavadinimas',
  msg_auction_create_type_buy: 'Perku',
  msg_auction_create_type_sell: 'Parduodu',
  msg_auction_declined_status: 'Atmestas',
  msg_auction_delete_modal_title: 'Ar tikrai norite ištrinti aukcioną?',
  msg_auction_delete_success_modal_title: 'Aukcionas ištrintas!',
  msg_auction_description_label: 'Aprašymas',
  msg_auction_ended_status: 'Pasibaigęs',
  msg_auction_expired_status: 'Negaliojantis',
  msg_auction_location_label: 'Vietovė',
  msg_auction_no_bidders_placeholder: '-',
  msg_auction_page_hero_title: 'Įkelkite aukcioną',
  msg_auctions_created_tab: 'Sukurti',
  msg_auctions_filter_placeholder: 'Rodyti',
  msg_auction_create_bid_success_modal_title: 'Kainos pasiūlymas pateiktas!',
  msg_auction_create_category_select_placeholder: 'Pasirinkite kategoriją',
  msg_auction_create_description_field: 'Pateikite kuo detalesnį prekės aprašymą.',
  msg_auction_create_description_field_placeholder: 'Aprašymas',
  msg_auction_create_duration_field_description: 'Nurodykite aukciono trukmę dienomis.',
  msg_auction_create_duration_field_description_placeholder: 'Pasirinkite aukciono trukmę (dienomis)',
  msg_auction_create_image_field: 'Nuotraukos',
  msg_auction_create_price_field_description_when_purchase:
    'Siekiant pritraukti aukciono dalyvius, rekomenduojame pasirinkti patrauklią pradinę kainą.',
  msg_auction_create_price_field_placeholder: 'Nurodykite pradinę kainą',
  msg_btn_decline_order: 'Atmesti užsakymą',
  msg_btn_create_payment_card: 'Pridėti',
  msg_auction_bid_price: 'Paskutinė kaina',
  msg_auction_breadcrumb: 'Aukcionai',
  msg_auction_create_auction_main_information: 'Pagrindinė informacija',
  msg_auction_create_auction_price_and_time: 'Kaina ir trukmė',
  msg_auction_create_auction_top_title: 'Aukciono informacija',
  msg_auction_create_bid_amount_placeholder: 'Nurodykite kainą',
  msg_auction_create_bid_place_amount_btn: 'Siūlyti kainą',
  msg_auction_create_bid_purchase_amount_description: 'Norėdami dalyvauti aukcione įveskite mažesnę kainą.',
  msg_auction_create_bid_sale_amount_description: 'Norėdami dalyvauti aukcione įveskite didesnę kainą.',
  msg_advert_display_no_my_ads_found: 'Skelbimų nėra',
  msg_advert_event_delete_modal_title: 'Ar tikrai norite ištrinti įrašą?',
  msg_advert_event_delete_success_modal_title: 'Įrašas ištrintas!',
  msg_advert_images_field: 'Nuotraukos',
  msg_advert_page_hero_subtitle:
    'Kelkite nelimituotą kiekį skelbimų. Norėdami paskelbti savo prekę ar paslaugą, užpildykite žemiau esančią skelbimo formą',
  msg_advert_page_hero_title: 'Jokių skelbimo įkėlimo mokesčių!',
  msg_advert_pricing_type_free_item_price: 'Dovanoja',
  msg_advert_pricing_type_negotiable_price: 'Kaina sutartinė',
  msg_advert_update_advert_save_advert_btn: 'Pakeisti skelbimą',
  msg_auction_auction_created_title: 'Aukcionas sukurtas!',
  msg_advert_create_service_type: 'Skelbimo tipas',
  msg_advert_create_service_type_good: 'Prekė',
  msg_advert_create_service_type_service: 'Paslauga',
  msg_advert_create_tab_additional_information: 'Papildoma informacija',
  msg_advert_create_tab_general_information: 'Bendra informacija',
  msg_advert_create_title_field: 'Skelbimo pavadinimas',
  msg_advert_create_title_field_placeholder: 'Įvardinkite ką norite parduoti ar pirkti',
  msg_advert_delivery_type_no_delivery: 'Pristatymo kaina neįskaičiuota',
  msg_advert_delivery_type_optional: 'Yra pristatymo galimybė',
  msg_advert_delivery_type_required: 'Pristatymas įskaičiuotas į kainą',
  msg_advert_display_ad_created_title: 'Sveikiname sukūrus skelbimą!',
  msg_advert_display_advert_views_count: 'Peržiūrėjo',
  msg_advert_display_create_company_first: 'Sukurkite ūkį, norėdami pateikti užsakymą.',
  msg_ad_expired: 'Skelbimo laikas pasibaigęs',
  msg_advert_create_advert_save_advert_btn: 'Sukurti skelbimą',
  msg_advert_create_advert_top_title: 'Skelbimo forma',
  msg_advert_create_advert_type: 'Skelbimo tikslas',
  msg_advert_create_advert_type_buy: 'Pirkti',
  msg_advert_create_advert_type_sell: 'Parduoti',
  msg_advert_create_delivery_price_field: 'Nurodykite atvežimo kainą',
  msg_advert_create_delivery_price_field_description:
    'Orientacinę atvežimo kainą nurodykite tuo atveju, jeigu turite galimybę prekę ar paslaugą pristatyti',
  msg_advert_create_delivery_price_field_placeholder: 'Nurodykite atvežimo kainą',
  msg_advert_create_description_field: 'Aprašymas',
  msg_advert_create_description_field_description:
    'Nurodykite bet kokią naudingą informaciją apie prekę ar paslaugą, pristatymo sąlygas ir kt.',
  msg_advert_create_description_field_placeholder: 'Aprašykite prekę ar paslaugą',
  msg_advert_create_other_information: 'Kita informacija',
  msg_advert_create_price_field: 'Nustatykite kainą ir matavimo vienetą',
  msg_advert_create_price_field_placeholder: 'Įrašykite kainą',
  msg_advert_create_price_type_selection_placeholder: 'Pasirinkite kainos tipą',
  msg_advert_create_schedule_enabled_field: 'Sukurti paslaugos prieinamumo tvarkaraštį',
  msg_add_auction_btn: 'Sukurti aukcioną',
  msg_advert_additional_images_field: 'Papildomos nuotraukos',
  msg_advert_change_delivery_type: 'PRISTATYMAS',
  msg_advert_create_advert_category_select: 'Skelbimo kategorija',
  msg_advert_create_advert_category_select_placeholder: 'Pasirinkite kategoriją iš sąrašo',
  msg_advert_create_advert_location_field: 'Pasirinkite vietovę',
  msg_advert_create_advert_location_field_placeholder: 'Vietovė',
  msg_advert_create_advert_pricing_type_free_item: 'Dovanoja',
  msg_advert_create_advert_pricing_type_negotiable_price: 'Kaina sutartinė',
  msg_calendar_event_delete: 'Ištrinti įrašą',
  msg_error_invalid_min_amount: 'Netinkamai nurodytas kiekis.',
  msg_preview_my_auction_title: 'Peržiūrėti aukcioną',
  msg_landing_page_categories_header: 'Kategorijos',
  msg_btn_login_apple: 'Sign in with Apple',
  msg_label_bank_transfer_payment_type: 'Banko pavedimas',
  msg_label_bank_transfer: 'Banko pavedimas',
  msg_order_transfer_payment_form_header_seller: 'Banko pavedimo patvirtinimas',
  msg_order_transfer_received_header: 'Patvirtinkite, kad gavote apmokėjimą banko pavedimu',
  msg_btn_confirm_transfer_received: 'Patvirtinti',
  msg_userpage_transfer_info_tab: 'Banko pavedimas',
  msg_userpage_transfer_info_form_company_name: 'Įmonės pavadinimas/Vardas, pavardė',
  msg_userpage_transfer_info_form_iban: 'Sąskaitos numeris',
  msg_userpage_transfer_info_form_bank_name: 'Banko pavadinimas',
  msg_userpage_transfer_info_button_update: 'Pateikti',
  msg_bank_info_updated_title_edit: 'Informacija išsaugota!',
  msg_bank_info_updated_subtitle_edit: 'Šią informaciją galėsite keisti nustatymuose.',
  msg_confirm_transfer_received_title: 'Patvirtinu gautą apmokėjimą',
  msg_confirm_transfer_received_subtitle: 'Patvirtinu, kad buvao gautas apmokėjimas banko pavedimu už šį užsakymą.',
  msg_confirm_transfer_received_btn: 'Patvirtinti',
  msg_confirm_transfer_received_confirmed_title: 'Užsakymo apmokėjimas sėkmingai patvirtintas.',
  msg_error_only_letters_and_numbers_allowed: 'Čia galite įvesti tik raides ir skaičius.',
  msg_label_order_payment_done: 'Mokėjimas įvykdytas!',
  msg_page_invitation_key_not_found: 'Kvietimo nuoroda nebegalioja.',
  msg_btn_invitation_key_not_found: 'Supratau',
  msg_page_join_company: 'Prisijunkite prie bendros paskyros!',
  msg_page_switch_kind: 'Pasirinkite',
  msg_auction_create_new_auction: 'Sukurti aukcioną',
  msg_label_auction_ended_inform_winner: 'Aukcionas baigėsi! Sveikiname laimėjus!',
  msg_error_invalid_date_diff: 'Minimalus užsakymo laikas - 3 val.',
  msg_order_transfer_payment_form_header_buyer: 'Banko pavedimo informacija',
  msg_transfer_info_for_buyer_company_name: 'Įmonės pavadinimas/Vardas, pavardė',
  msg_transfer_info_for_buyer_iban: 'Sąskaitos numeris',
  msg_transfer_info_for_buyer_bank_name: 'Banko pavadinimas',
  msg_advert_additional_images_description: 'Galite pridėti 4 papildomas nuotraukas (iki 5MB).',
  msg_label_agreed_payment_type: 'Mokėjimas pagal susitarimą',
  msg_auction_create_price_field_description_when_sell:
    'Siekiant pritraukti aukciono dalyvius, rekomenduojame pasirinkti patrauklią pradinę kainą.',
  msg_advert_create_schedule_enabled_field_description:
    'Norėdami nurodyti specifinius laikus, kada Jūsų paslauga yra pasiekiama, pažymėkite varnelę ir pridėkite datas bei laikus. Nenustačius konkrečių laikų, Jūsų paslauga bus visada pasiekiama',
  msg_advert_main_image_field: 'Pagrindinė nuotrauka',
  msg_lt_lang: 'testas',
  msg_preview_auction_title: 'Aukcionas',
  msg_error_dates_out_of_schedule: 'Netinkamos datos, pasitikrinkite kalendorių!',
  msg_error_invalid_advert_pricing_type: 'Netinkamas kainos formatas',
  msg_open_preliminary_order: 'Pateikti užklausą',
  msg_submit_preliminary_order: 'Pateikti',
  msg_cancel_preliminary_order: 'Atšaukti',
  msg_create_preliminary_order: 'Pateikti užklausą',
  msg_order_form_is_disabled: 'Užsakymo forma šiuo metu neveikia',
  msg_preliminary_order_start_date_older_today: 'Pasirinkta data negali būti ankstesnė nei šios dienos data',
  msg_preliminary_order_email_not_valid: 'Netinkamas el. pašto adresas',
  msg_preliminary_form_contact_method_missing: 'Pasirinkite susisiekimo būdą',
  msg_preliminary_form_contact_only_one_method: 'Galimas tik vienas susisiekimo būdas',
  msg_preliminary_form_phone_not_provided: 'Nepateikas telefono numeris',
  msg_preliminary_form_email_not_provided: 'Nepateikas el. pašto adresas',
  msg_preliminary_form_contact_platform_no_user: 'Nerastas vartotojas',
  msg_preliminary_order_creation: 'Gauti pasiūlymą',
  msg_preliminary_order_date: 'Data',
  msg_preliminary_order_comment: 'Jūsų užklausa',
  msg_preliminary_order_contact_type_title: 'Kaip su jumis susisiekti?',
  msg_preliminary_order_contact_via_phone: 'Telefonu',
  msg_preliminary_order_contact_via_email: 'El. paštu',
  msg_preliminary_order_contact_via_platform: 'Per platformą',
  msg_preliminary_order_phone: 'Telefonas',
  msg_preliminary_order_email: 'El. paštas',
  msg_preliminary_order_name: 'Vardas Pavardė',
  msg_contact_phone: 'Susisiekite: {{phone}}',
  msg_preliminary_order_create_order_success: 'Užklausa pateikta',
  msg_preliminary_order_create_order_success_subtext: 'Gavome jūsų užklausą ir susisieksime artimiausiu metu',
  msg_rent_banner_title: 'Naujų Valtra traktorių nuoma',
  msg_page_rent_info_title: 'Valtra traktorių nuoma',
  msg_no_ads_in_category_found: 'Rezultatų nerasta',
  msg_ad_contact_us: 'Susisiekti',
  msg_error_wrong_image_format: 'Netinkamas paveikslėlio formatas',
  msg_error_file_size_too_large: 'Failo dydis per didelis',
  msg_error_wrong_number: 'Neteisingas skaičius',
  msg_error_only_whole_numbers_allowed: 'Leidžiami tik sveikieji skaičiai',
  msg_label_order_status_canceled: 'Atšauktas',
  msg_label_order_status_payment_pending: 'Laukiama mokėjimo',
  msg_label_order_status_declined: 'Atmestas',
  msg_label_order_status_expired: 'Nebegaliojantis',
  msg_label_order_status_ended: 'Pasibaiges',
  msg_label_order_status_finished: 'Pabaigtas',
  msg_label_order_status_unfinished: 'Nepabaigtas',
  msg_order_summary_administration_fee: 'Administravimo mokestis',
  msg_order_summary_vat: 'PVM',
  msg_label_something_went_wrong: 'Netikėta klaida',
  msg_label_order_updated_status: 'Užsakymo būsena pasikeitė!',
  msg_edit_auction_page_hero_title: 'msg_edit_auction_page_hero_title',
  msg_edit_auction_page_hero_subtitle: 'msg_edit_auction_page_hero_subtitle',
  msg_edit_auction_success_btn: 'msg_edit_auction_success_btn',
  msg_edit_auction_success_title: 'msg_edit_auction_success_title',
  msg_edit_auction_success_subtitle: 'msg_edit_auction_suvccess_subtitle',
  msg_page_email_verification: 'msg_page_email_verification',
  msg_page_register_success: 'msg_page_register_success',
  msg_edit_auction_title: 'msg_edit_auction_title',
  msg_sejico_agro_header_link: 'Sejico Agro',
  msg_sejico_construction_header_link: 'Sejico Construction',
  msg_landing_page_hero_title_agro: 'Naujų Valtra traktorių nuoma',
  msg_landing_page_hero_title_constructions: 'Statybinės technikos nuoma',
  msg_landing_page_hero_subtitle_agro:
    'Siūlome Jums išsinuomoti įvairiausio galingumo skandinaviškus Valtra traktorius',
  msg_landing_page_hero_subtitle_constructions: 'Siūlome Jums išsinuomoti įvairios statybinės technikos',
  msg_landing_page_hero_button: 'Peržiūrėkite pasiūlymus',
  msg_landing_search_form_search_input: 'Įveskite paieškos žodį',
  msg_landing_search_form_categories_select_input: 'Pasirinkite kategoriją iš sąrašo',
  msg_landing_clear_filters_button: 'Išvalyti',
  msg_landing_detailed_search_button: 'Detali paieška',
  msg_delete_user_section_heading: 'Vartotojo paskyros ištrynimas',
  msg_delete_user_section_button: 'Ištrinti',
  msg_delete_user_modal_title: 'Ar tikrai norite ištrinti vartotojo paskyrą?',
  msg_delete_user_success_modal_subtitle: 'Paskyra bus ištrinta per 30 dienų',
  msg_other_ads_category_title: 'Kiti skelbimai',
  msg_category_equipment_rent: 'Technikos nuoma',
  msg_category_agricultural_machinery_services: 'Žemės ūkio technikos paslaugos',
  msg_category_construction_machinery: 'Statybinė technika',
  msg_category_construction_machinery_services: 'Statybos technikos paslaugos',
  msg_landing_popular_cats_title: 'Populiariausios kategorijos',
  msg_button_view_add: 'Žiūrėti',
  msg_featured_tab_daily_promotion: 'Dienos pasiūlymas',
  msg_featured_tab_most_expensive: 'Brangiausi',
  msg_featured_tab_most_recent: 'Nauji',
  msg_featured_tab_most_popular: 'Populiariausi',
  msg_featured_ads_section_agro_title: 'Žemės ūkio technikos paslaugos',
  msg_featured_ads_section_construction_title: 'Statybos technikos paslaugos',
  msg_loan_form_title: 'Paskolos skaičiuoklė',
  msg_loan_form_loan_sum: 'Pasirinkite paskolos sumą',
  msg_loan_form_how_to:
    'Kaip įsigyti automobilį išsimokėtinai? Galite pasiskolinti iki 30 000 €. Pasirinkite pageidaujamą mėnesio įmoką ir užpildykite paraišką finansavimui gauti. Jeigu atsakymas teigiamas - pinigus pervesime į jūsų sąskaitą. Netaikomi apribojimai automobilio amžiui ir jį rinktis galite iš bet kurio pardavėjo, taip pat nebūtinas KASKO draudimas. Grąžinti galite ir anksčiau termino be papildomų mokesčių, pavyzdžiui, pardavę automobilį.',
  msg_loan_form_monthly_payment: 'Pasirinkite mėnesio įmoką:',
  msg_loan_form_loan_price: 'Jūsų paskolos kaina:',
  msg_loan_form_contact: 'Kaip su jumis susisiekt?',
  msg_loan_form_phone: 'Telefonu',
  msg_loan_form_email: 'El.paštu',
  msg_loan_form_enter_email: 'Įveskite savo el. pašto adresą',
  msg_loan_form_enter_phone: 'Įveskite savo tel. numerį',
  msg_loan_form_get_offer_label: 'Gaukite pasiulymą',
  msg_loan_form_example:
    'Pavyzdžiui, jei suteikiamos paskolos suma yra 18 000 €, kai sutartis sudaroma 72 mėn. terminui, kintama metinė palūkanų norma - 3.5% + 6 mėn. EURIBOR (4.074%), sutarties sudarymo mokestis – 3%, mėnesinis administravimo mokestis – 9.5 €, BVKKMN – 10.13%, bendra mokėtina suma – 23 807.84 €, mėnesio įmoka – 330.66 €.  Jums gali būti pasiūlytos kitos paskolos internetu sąlygos, kurios priklauso nuo banko atlikto jūsų kreditingumo bei rizikos vertinimo.',
  msg_mobile_header_favorite_ads: 'Patikę skelbimai',
  msg_mobile_header_activity: 'Pranešimai',
  msg_advert_loan_form_success_message: 'Užklausa sėkmingai išsiųsta. Su jumis susisieksime artimiausiu metu.',
  msg_header_link_sejico_finance_title: 'Sejico Finance',
  msg_label_default_date_input_placeholder: 'Pasirinkinte datą',
  msg_error_loan_duration_cannot_be_greater: 'Paskolos trukmė negali būti ilgesnė nei maksimali paskolos trukmė',
  msg_error_loan_amount_cannot_be_greater: 'Paskolos suma negali būti didesnė už maksimalią paskolos sumą',
  msg_newsletter_section_title: 'Naujienlaiškis',
  msg_newsletter_section_subtitle:
    'Užsiprenumeruokite Sejico naujienlaiškį - gaukite informaciją ir pasiūlymus pirmieji!',
  msg_newsletter_section_email_input_placeholder: 'Įveskite el. pašto adresą',
  msg_subscribe_button: 'Prenumeruoti',
  msg_newsletter_section_error: 'Įvyko klaida',
  msg_newsletter_section_subscribe_success: 'Naujienlaiškio prenumerata užsakyta!',
  msg_make: 'Gamintojas/Markė',
  msg_model: 'Modelis',
  msg_year_of_manufacture: 'Pagaminimo metai',
  msg_defects: 'Defektai',
  msg_make_date: 'Pagaminimo data',
  msg_type: 'Tipas',
  msg_engine_make: 'Variklio gamintojas',
  msg_gross_weight: 'Bendras svoris',
  msg_about_page_title: 'Apie mus',
  msg_about_page_subtitle:
    'Mūsų misija yra suvienyti ūkininkus ir verslus bendradarbiavimui, siekiant pirkti/parduoti paslaugas ir/ar prekes.',
  msg_about_page_advantage_title_1: 'Išsinuomokite Sejico Techniką',
  msg_about_page_advantage_subtitle_1:
    'Ilgalaikė ir trumpalaikė traktorių nuoma nuo vienos savaitės iki vienerių metų. Šiuo metu siūlome išsinuomoti nuo 170 AG iki 300 AG Valtra traktorius.',
  msg_about_page_advantage_title_2: 'Gaukite ž.ū. paslaugas iš kitų ūkininkų',
  msg_about_page_advantage_subtitle_2:
    'Esant reikalui gauti ž.ū. paslaugas iš kolegų ūkininkų. Su Sejico pagalba rasite kolegas ūkininkus kurie padės atlikti Jums reikalingus žemės ūkio darbus.',
  msg_about_page_advantage_title_3: 'Teikite ž.ū. paslaugas kitiems ūkininkams',
  msg_about_page_advantage_subtitle_3:
    'Laisvu laiku nuo darbų savo ūkyje uždirbti atliekant ž.ū. paslaugas kitiems ūkininkams. Sejico - tarpininkauja ir suteikia sąlygas ūkininkams sąlygas nuomoti jiems priklausančią techniką ir atlikti įvairius ž.ū. darbus.',
  msg_about_page_benefits_section_title: 'Sejico - čia susitinka ūkininkai!',
  msg_about_page_benefits_section_subtitle:
    'Sejico suteikiamos naudos ūkininkams teikiantiems ž.ū. paslaugas ar nuomojantiems techniką:',
  msg_about_page_benefits_section_benefit_1: 'Efektyvus technikos išnaudojimas lemia daug greitesnį atsipirkimą.',
  msg_about_page_benefits_section_benefit_2: 'Suteikiama galimybė dirbti tik su patikimais klientais.',
  msg_about_page_benefits_section_benefit_3:
    'Galimybė sutaupyti neperkant naujos technikos jei ji išnaudojama neracionaliai.',
  msg_about_page_benefits_section_benefit_4:
    'Trumpalaikė technikos nuoma leis atlikti darbus sugedus nuosavai technikai.',
  msg_about_page_benefits_section_benefit_5:
    'Nuoma ar ž.ū. paslauga suteikia galimybę išbandyti techniką bei naujausias technologijas dar prieš įsigyjant naują pirkinį.',
  msg_landing_partners_section_title: 'Partneriai',
  msg_general_order_min_info_part_1:
    'Neradote Jus dominančios paslaugos arba norite užsisakyti žemės ūkio paslaugą? Užpildykite šią formą, kad gautumėte geriausią pasiūlymą.',
  msg_general_order_min_info_part_2:
    'Sejico.lt tarpininkauja teikiant paslaugas ir garantuoja darbų atlikimą bei atsiskaitymą laiku. Paslauga bus suteikiama Sejico turima technika arba Sejico partnerių / kitų platformos dalyvių pagalba. Sejico suteikia mokėjimo atidėjimo galimybę.',
  msg_general_order_condition_full_payment: 'Be atidėjimo (mokama 80% avansu, 20% iškart po darbų)',
  msg_general_order_condition_fifty_fifty_split:
    '14d. atidėjimas (50% avansu, 50% po darbų atlikimo per 14d.) +5% atidėjimo mokestis',
  msg_general_order_condition_after_thirty_days_with_advance:
    '30d. atidėjimas (20% avansu, 80% po darbų atlikimo per 30d.) +9% atidėjimo mokestis',
  msg_general_order_create_order_success: 'Užklausa pateikta',
  msg_general_order_create_order_success_subtext: 'Gavome jūsų užklausą ir susisieksime artimiausiu metu',
  msg_general_order_link: 'Užsakymo forma',
  msg_general_order_acceptance_link: 'Užsakymo priėmimo forma',
  msg_general_order_field_amount: 'Kiekis',
  msg_general_order_field_field_size_placeholder: 'Laukas {{fieldNumber}} (ha)',
  msg_general_order_field_number_of_fields_placeholder: 'Laukų kiekis',
  msg_general_order_field_amount_placeholder: 'Nurodykite kiekį',
  msg_general_order_field_location_title: 'Nurodykite kur jums reikalinga paslauga',
  msg_general_order_field_location_placeholder: '',
  msg_general_order_field_label_select_market_price: 'Pasirinkite kainą iš rinkos intervalo',
  msg_general_order_field_payment_condition: 'Pasirinkite apmokėjimo terminą:',
  msg_general_order_field_payment_condition_label: 'Pasirinkite apmokėjimo terminą:',
  msg_general_order_field_payment_condition_explainer:
    'Sejico suteikia galimybę gauti mokėjimo atidėjimą. Sejico komisinis mokesčio dydis priklauso nuo atidėjimo trukmės. Sejico komisinis gali būti 2,5%, 5% arba 9%.',
  msg_general_order_full_price_display: 'Visa kaina:',
  msg_general_order_field_start_date: 'Data nuo',
  msg_general_order_field_end_date: 'Data iki',
  msg_general_order_field_name: 'Vardas',
  msg_general_order_field_surname: 'Pavardė',
  msg_general_order_field_amount_type: 'Tipas',
  msg_general_order_field_additional_info: 'Papildoma informacija',
  msg_general_order_field_terrain_type: 'Reljefo / Žemės tipas',
  msg_general_order_field_market_price_explainer:
    'Paslaugos kaina yra preliminari ir galutinę kainą sužinosite, kai bus įvertinti visi įtaką turintys veiksniai',
  msg_general_order_error_invalid_total_price: 'Neteisinga bendra kaina',
  msg_general_order_error_invalid_terrain_type: 'Neteisingas reljefo tipas',
  msg_general_order_error_end_date_musts_be_before_start_date: 'Pabaigos data turi būti prieš pradžios datą',
  msg_general_order_error_invalid_general_order: 'Neteisingas užsakymas',
  msg_general_order_field_option_label_ha: 'ha',
  msg_accept_general_order_page_title: 'Užsakymo patvirtinimas',
  msg_general_offer_create_order_success: 'Pasiūlymas pateikta',
  msg_general_offer_create_order_success_subtext: 'Gavome jūsų pasiūlymq ir susisieksime artimiausiu metu',
  msg_general_offer_field_radio_agree_with_price: 'Sutinku su kaina',
  msg_general_offer_field_radio_offer_your_price: 'Pasiūlyti savo kainą',
  msg_general_offer_field_offered_price_placeholder: 'Įveskite savo kainą (EUR)',
  msg_general_offer_condition_full_payment: 'Be atidėjimo (mokama iškart po darbų) -9%',
  msg_general_offer_condition_fifty_fifty_split: '14d. atidėjimas (po darbų atlikimo per 14d.) -5%',
  msg_general_offer_condition_after_thirty_days_with_advance: '30d. atidėjimas (po darbų atlikimo per 30d.) -2.5 %',
  msg_general_offer_offer_amount_display: 'Kiekis:',
  msg_general_offer_offer_fields_display: 'Laukai:',
  msg_general_offer_order_price_display: 'Užsakymo kaina:',
  msg_general_offer_final_price_display: 'Galutinė kaina:',
  msg_general_offer_terrain_type_display: 'Reljefo / Žemės tipas:',
  msg_general_offer_field_category_title: 'Pasirinkite Jums reikalingą ž.ū. paslaugą',
  msg_general_offer_field_category_placeholder: 'Žemės ūkio paslauga',
  msg_create_contractor_page_title: 'Rangovo forma',
  msg_create_contractor_page_link: 'Rangovo forma',
  msg_contractor_create_success: 'Užklausa pateikta',
  msg_contractor_create_success_subtext: 'Gavome jūsų užklausą ir susisieksime artimiausiu metu',
  msg_contractor_field_category_details_placeholder: 'Darbams numatyta technika',
  msg_contractor_field_working_area_ray_placeholder: 'Atstumas kuriame sutinkate teikti paslaugą (Km)',
  msg_contractor_field_category_price_type_eur_per_hour_placeholder: 'EUR už valandą',
  msg_contractor_field_category_price_type_eur_per_hectare_placeholder: 'EUR už hektarą',
  msg_contractor_field_category_price_placeholder: 'Kaina',
  msg_contractor_field_category_price_type_placeholder: 'Tipas',
  msg_contractor_form_general_info:
    'Sejico suteikia galimybę atlikti žemės ūkio paslaugas kitiems ūkininikams, bendrovėms ir įmonėms. Jeigu turite galimybę atlikti bet kokia žemės ūkio paslaugą, kviečiame užpildyti RANGOVO formą ir Sejico komanda pasiūlys darbų pagal Jūsų užpildytus kriterijus.',
} as const;
